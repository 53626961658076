let map_data = {};

$(function() {
  $('.ranking-map-pill-btn').on('click', function() {
    let itemId = $(this).closest('.ranking-map').data().itemId;
    let mapIndex = $(this).data().mapIndex;
    if (!map_data[itemId][mapIndex]['map']) setMap(itemId, mapIndex);

    $(this).closest('.ranking-map').find('.ranking-map-content').addClass('ranking-map-content-hidden');
    $('#ranking-map-content-' + itemId + '-' + mapIndex).removeClass('ranking-map-content-hidden');
    $(this).closest('.ranking-map').find('.ranking-map-pill-btn').removeClass('current');
    $(this).addClass('current');
  });
});

window.initMap = function() {
  $('.ranking-map').each(function() {
    let itemId = $(this).data().itemId;
    map_data[itemId] = $(this).find('.ranking-map-content').map(() => ({ map: null, markers: [] }));
  });

  $.each(map_data, (itemId, data) => {
    setMap(itemId, 0);
  });
}

function setMap(itemId, mapIndex) {
  let locations = $('#ranking-map-map-' + itemId + '-' + mapIndex).data().locations;
  locations = locations.map(l => ({ lat: parseFloat(l.lat), lng: parseFloat(l.lng) }));
  let centerLat = locations.reduce((sum, current) => sum + current.lat, 0) / locations.length;
  let centerLng = locations.reduce((sum, current) => sum + current.lng, 0) / locations.length;

  map_data[itemId][mapIndex]['map'] = new google.maps.Map(document.getElementById('ranking-map-map-' + itemId + '-' + mapIndex), {
    center: { lat: centerLat, lng: centerLng },
    zoom: 15,
    disableDefaultUI: true,
    styles: [ { "featureType": "landscape", "elementType": "geometry", "stylers": [ { "lightness": 25 } ] }, { "featureType": "poi", "elementType": "geometry", "stylers": [ { "lightness": 25 } ] }, { "featureType": "poi", "elementType": "labels.icon", "stylers": [ { "color": "#d2d2d2" } ] }, { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [ { "color": "#949499" } ] }, { "featureType": "road", "stylers": [ { "lightness": 25 } ] }, { "featureType": "road", "elementType": "labels", "stylers": [ { "saturation": -100 }, { "lightness": 40 } ] }, { "featureType": "transit.station.airport", "elementType": "geometry", "stylers": [ { "lightness": 25 } ] }, { "featureType": "water", "elementType": "geometry", "stylers": [ { "lightness": 25 } ] } ]
  });

  map_data[itemId][mapIndex]['markers'] = locations.map((l, i) => {
    let marker = new google.maps.Marker({
      position: l,
      map: map_data[itemId][mapIndex]['map']
    });
    marker.addListener('click', () => {
      $('#ranking-map-shops-' + itemId + '-' + mapIndex).slick('slickGoTo', i);
    });
    return marker;
  });

  let map = map_data[itemId][mapIndex]['map'];
  let markers = map_data[itemId][mapIndex]['markers'];

  $('#ranking-map-shops-' + itemId + '-' + mapIndex).on('beforeChange', (e, slick, currentSlide, nextSlide) => {
    changeMarkerIcon(itemId, mapIndex, nextSlide);
    if (!map.getBounds().contains(markers[nextSlide].getPosition())) map.panTo(markers[nextSlide].getPosition());
  });

  changeMarkerIcon(itemId, mapIndex, 0);
}

function changeMarkerIcon(itemId, mapIndex, markerIndex) {
  $.each(map_data[itemId][mapIndex]['markers'], (i, marker) => {
    let url = 'https://pappa.s3.ap-northeast-1.amazonaws.com/icons/' + (i == markerIndex ? 'marker_active.png' : 'marker.png');
    let scaledSize = i == markerIndex ? new google.maps.Size(44, 50) : new google.maps.Size(28, 35);
    let zIndex = i == markerIndex ? 99 : i;
    
    marker.setIcon({ url: url, scaledSize: scaledSize });
    marker.setZIndex(zIndex);
  });
}
