// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "lib/map"

Rails.start()
ActiveStorage.start()

// import '@fortawesome/fontawesome-free/js/all'
import '../src/application'

$(function() {
  var footerHeight = $('#footer').outerHeight();
  $('body').css('padding-bottom', footerHeight + 96);
});

$(function() {
  $('body').on('click', 'a[href^="#"]', function() {
    var href = $(this).attr('href');
    var target = href == '#' ? 'html' : href;
    var position = $(target).offset().top;

    $('html, body').animate({ scrollTop: position }, 500, 'swing');
    if ($(window).scrollTop() < position) {
      $('.scroll-btn').removeClass('hidden');
    } else {
      $('.scroll-btn').addClass('hidden');
    }
  });
});

$(function() {
  $('.top-pref-btn').on('click', function() {
    $('.top-area-1st').addClass('move-left');
    $('.top-area-2nd').addClass('move-left');

    var target = $(this).data().target;
    $('.top-pref-box').addClass('hidden');
    $(target).removeClass('hidden');

    if ($(window).width() <= 720) {
      var position = $('#top-area-2nd').offset().top - 32;
      $('html, body').scrollTop(position);
    }
  });

  $('.top-pref-back-btn').on('click', function() {
    $('.top-area-1st').removeClass('move-left');
    $('.top-area-2nd').removeClass('move-left');
  });
});

$(function() {
  $('.ranking-map-shops').slick({
    infinite: false,
    arrows: false,
    centerMode: true,
    centerPadding: '96px',
    touchThreshold: 10,
    responsive: [{
      breakpoint: 720,
      settings: {
        centerPadding: '24px'
      }
    }]
  });

  $('.ranking-map-shop').removeClass('hidden');
  $('.ranking-map-shop').find('.shop-name, .shop-info-text, .shop-plan-price').addClass('line-clamp');
});

$(function() {
  startShopImagesSlick();
});

$(function() {
  $('.sort-select').on('change', function() {
    var target = $(this).data().target;
    var dataName = $(this).val();

    $('.shop-images').slick('unslick');

    $(target).each(function() {
      $(this).html(
        $(this).find('.sort-item').sort(function(a, b) {
          var data_a = $(a).data(dataName);
          var data_b = $(b).data(dataName);
          for (var i = 0; i < data_a.length; i++) {
            if (data_a[i] > data_b[i]) return 1;
            if (data_a[i] < data_b[i]) return -1;
          }
          return 1;
        })
      );

      $(this).find('.sort-rank').each(function(i) {
        $(this).html(i + 1);
      });
    });

    startShopImagesSlick();
  });
});

$(function() {
  $('body').on('click', '.accordion-btn', function() {
    $(this).closest('.accordion').find('.accordion-content').first().slideToggle();

    var $hiddenIcon = $(this).find('.accordion-btn-icon.hidden');
    $(this).find('.accordion-btn-icon').addClass('hidden');
    $hiddenIcon.removeClass('hidden');
  });
});

$(function() {
  var loaded = {};

  $('body').on('click', '.map-btn', function() {
    var target = $(this).data().target;

    if (loaded[target]) return;
    loaded[target] = true;

    $(target).html($('<iframe>', {
      style: 'border: 0',
      allowfullscreen: true,
      src: $(this).data().src
    }));
  });
});

$(function() {
  $('.item-group').each(function() {
    var $group = $(this);
    var items = [];

    var count = parseInt($group.data().count);
    var $before = $group;

    for (var i=0; i<count; i++) {
      var $current = $before.next();
      if (!$current.hasClass('itemable') || $current.hasClass('item-group')) break;
      items.push($current);
      $before = $current;
    }

    $.each(items, function(i, $item) {
      $group.find('.item-group-content').append($item);
    });
  });
});

function startShopImagesSlick() {
  $('.shop-images').slick({
    lazyLoad: 'progressive',
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [{
      breakpoint: 720,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });
}

window.addNotesToBrandLogo = function(brand_ids, area_name) {
  $('.brand-logo').each(function() {
    var brand_id = parseInt($(this).data().brandId);
    if (!brand_id || brand_ids.includes(brand_id)) return;
    $(this).after('<div class="brand-logo-notes">' + area_name + 'エリアにはありません</div>');
  });
}
